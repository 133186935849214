import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { getUsers,setAdmin } from "../services";
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import '../css/UserList.css';
import { updateUserAdminStatus,updateUsers } from '../actions'; // Import your Redux actions

const RolePermission = () => {
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const isSuperAdmin = sessionStorage.getItem('isSuperAdmin');
  const users = useSelector((state) => state.users);

  useEffect(() => {
    const fetchUsers = async () => {
    try {
      let data = { isSuperAdmin};
      setLoading(true);
      getUsers(data).then(res => {
        if (res.status == 200) {
          const data =  res.data;
          console.log(res.data)
          setLoading(false);
          dispatch(updateUsers(data)); // Dispatch action to update Redux state
        }
      }).catch(err => {
        console.log(err)
        setError(err.response.message);
      })
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred during login.');
    }
    };

    fetchUsers();
  }, [dispatch, isSuperAdmin]);




  useEffect(() => {
    // Initialize DataTable when users data changes and loading is complete
    if (!loading) {
      initializeDataTable(users);
    }
  }, [users, loading]);


  const initializeDataTable = (data) => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        data,
        columns: [
          { title: 'Sr. No.', data: '_id' },
          { title: 'User Name', data: 'name' },
          { title: 'Email', data: 'email' },
          { title: 'Google ID', data: 'socialId' },
          {
            title: 'Role',
            data: 'isAdmin',
            render: function (data, type, row) {

              return `<button class="toggle-button isteacher isstudent_${row._id}  ${(data == 0 ? "greencolor" : "redcolor")}" >Student</button>     <button class="toggle-button isteacher isteach_${row._id} ${(data == 1 ? "greencolor" : "redcolor")}" data-user-id="${row._id}" data-is-admin="${data}">Teacher</button>`;
            },
          },
        ],
        destroy: true,
      });
  
      // Attach click event to toggle isAdmin status
      $(tableRef.current).off().on('click', '.isteacher', function (e) {
        e.preventDefault();
        const userId = $(this).data('user-id');
        const currentIsAdmin = $(this).data('is-admin');
        const newIsAdmin = (currentIsAdmin == 1 ? 0 : 1);
        const isSuperAdmin = 1;

        const btnt = `<button class="toggle-button isteacher isstudent_${userId} ${(newIsAdmin == 0 ? "greencolor" : "redcolor")}" data-user-id="${userId}" data-is-admin="${newIsAdmin}">Student</button>`;
           $('.isstudent_'+userId).replaceWith(btnt);
           
        const btn = `<button class="toggle-button isteacher isteach_${userId} ${(newIsAdmin == 1 ? "greencolor" : "redcolor")}" data-user-id="${userId}" data-is-admin="${newIsAdmin}">Teacher</button>`;
        $(".isteach_"+userId).replaceWith(btn);

  
        // Dispatch the Redux action to update the user's isAdmin status
         dispatch(updateUserAdminStatus(userId, newIsAdmin, isSuperAdmin));
        //window.location.reload(); 
      });
    }
  };




  return (
    <div className="user-list-container">
      <h2>Roles And Permission</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table ref={tableRef} className="user-list table table-bordered">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Google ID</th>
              <th>isAdmin</th>
            </tr>
          </thead>
        </table>
      )}
    </div>
  );
};

export default RolePermission;
