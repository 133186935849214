import axios from "axios"


// export const api_url= "http://localhost:8803/v1"
// export const AppUrl= "http://localhost:5173/"
export const api_url= "https://flashlingua.cards/api/v1"
export const AppUrl= "https://flashlingua.cards/"
// export const api_url = "https://test.flashlingua.cards/api/v1"
// export const AppUrl = "https://test.flashlingua.cards/"




export const userLogin = (payload)=>{
    return axios.post(`${api_url}/users/admin-login`,payload)
}

export const getUsers = (payload) => {
    return axios.post(`${api_url}/users/get-users`, payload)
}

export const setAdmin = (token, payload) => {
    return axios.post(`${api_url}/users/set-admin`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

}
