// actions.js
import { setAdmin } from "./services";
export const updateUsers = (users) => {
    console.log('Updated Users:', users); // Add this line for debugging
    return {
      type: 'UPDATE_USERS',
      payload: users,
    };
  };
  
  

// actions.js
export const updateUserAdminStatus = (userId, isAdmin, isSuperAdmin) => async (dispatch) => {
    try {
      const authToken = sessionStorage.getItem('authToken');
      const getAPiToken = localStorage.getItem("token");
      let data = { userId,isAdmin, isSuperAdmin };
      setAdmin(getAPiToken,data).then(res => {
        if (res.status == 200) {
          
        }
      }).catch(err => {
        console.log(err)
        alert(err.response.data.message);
      })
    } catch (error) {
      console.error('Error updating user isAdmin status:', error);
    }
  };
  
  

  // Thunk action creator
export const toggleUserAdminStatus = (userId, currentIsAdmin) => async (dispatch) => {
    try {
      // Perform any asynchronous operations here
      const updatedUser = await updateUserAdminStatus(userId, !currentIsAdmin);
  
      // Dispatch a regular action with the updated user
      dispatch({
        type: 'TOGGLE_USER_ADMIN_STATUS_SUCCESS',
        payload: { userId, updatedUser },
      });
    } catch (error) {
      // Dispatch an action for error handling
      dispatch({
        type: 'TOGGLE_USER_ADMIN_STATUS_FAILURE',
        payload: { error },
      });
    }
  };
  