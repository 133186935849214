import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { userLogin } from "../services";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      let data = { email, password };
      userLogin(data).then(res => {
        if (res.status == 200) {
          // console.log('res', res.data)
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("email", res.data.data.email)
          localStorage.setItem("isAdmin", res.data.data.isAdmin)
          localStorage.setItem("isSuperAdmin", res.data.data.isSuperAdmin)
          
           navigate('/super-admin');
        }
      }).catch(err => {
        console.log(err)
        setError(err.response.data.message);
      })
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred during login.');
    }
  };
  return (
    <div className="login-container">
      <h2 className="login-title">Flash Card</h2>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Login;